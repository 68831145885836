<template>
    <b-modal
      id="selected-bills-to-receive-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal()"
      @show="getUser"
    >
      <div class="modal-header">
        <div class="modal-title">Receber conta(s) selecionada(s)</div>
        <span class="icon-box"><v-close class="icon stroke" @click="closeModal"  /></span>
      </div>
  
      <div class="body-content">
        <validation-observer ref="BillToPayForm">
          <div class="row">
  
            <div class="col-4">
              <b-form-group id="field_date_release">
                <label for="field_date_release" id="modal-titles">Data do recebimento</label>
                <date-picker
                  class="full"
                  v-model="form.date_release"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/AAAA"
                  :clearable="false"
                  :lang="langDatePicker"
                ></date-picker>
              </b-form-group>
            </div>
  
            <div class="col-8">
              <b-form-group id="field_user">
                <label for="field_user" id="modal-titles">Lançamento realizado por</label>
                <b-form-input
                  autocomplete="off"
                  v-model="form.user"
                  :disabled="true"
                />
              </b-form-group>
            </div>
          </div>
        </validation-observer>
  
        <b-table-simple class="table">
          <thead>
            <tr>
              <td class="td-header">Tipo</td>
              <td class="td-header">Categoria</td>
              <td class="td-header">Vencimento</td>
              <td class="td-header">Pagamento</td>
              <td class="td-header">Conta</td>
              <td class="td-header text-right">Valor</td>
            </tr>
          </thead>
  
          <tbody>
            <tr v-for="installment in selectedInstallments" :key="installment.id">
              <td class="td-body">
                <div class="change-type">{{ installment.change === 'auto' ? 'Automático' : 'Manual' }}</div>
              </td>
              <td class="td-body category-text">
                {{ installment?.payment?.category?.name ?? '-' }}
              </td>
              <td class="td-body">
                <v-date-with-alert
                  :id="`due_date_${installment.id}`"
                  :date="installment.due_date"
                  :warning="installment.status === 'pending' && moment(installment.due_date).diff(moment(), 'days') <= 10"
                  :danger="installment.status === 'expired'"
                />
              </td>
              <td class="td-body">{{ installment?.payment?.payment_method?.name ?? '-' }}</td>
              <td class="td-body">{{ installment?.payment?.bank_account?.name ?? '-'}}</td>
              <td class="td-body text-right final-amount-text">
                {{ parseNumberToMoney(installment.amount) }}
              </td>
            </tr>
          </tbody>
  
          <b-tfoot>
            <b-tr>
              <b-td id="total-text">Total</b-td>
              <b-td colspan="4" class="text-right">
                <b id="total-amount">{{parseNumberToMoney(total)}}</b>
              </b-td>
              <b-td></b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
  
        <div class="hr">
          <hr/>
        </div>
  
        <div class="md-button">
          <b-button
            class="wh-button"
            variant="primary"
            size="lg"
            @click="startReceive">
            Receber conta
          </b-button>
        </div>
      </div>
    </b-modal>
  </template>
  
  <script>
  import moment from 'moment';
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  
  export default {
    name: 'BillToReceiveSelectedModal',
  
    props: {
      selectedInstallments: { type: Array, required: true },
      onEdit: { type: Function, required: true },
      getBillsToReceive: { type: Function, required: true }
    },
  
    components: {
      'v-close': () => import('@/assets/icons/close.svg'),
      'v-date-with-alert': () => import('@/components/General/DateWithAlert'),
    },
  
    watch: {
      selectedInstallments: function() {
        this.getFinalAmount();
      }
    },
    data() {
      const clinic = JSON.parse(localStorage.getItem('clinic'));
  
      return {
        clinic_id: clinic.id,
        validated: false,
        total: 0,
        errors: {},
        form: {
          id: null,
          user: null,
          date_release: new Date(),
        },
  
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'] // DIAS DA SEMANA NO CAMPO DATA
          }
        },
      }
    },
    methods: {
      moment,
      getUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.form.user = user.name
      },
      parseNumberToMoney,
      startReceive() {
        if (this.selectedInstallments.every(el => el.change === 'manual' )) {
          this.accountReceive()
          return
        }
  
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">Existem valores configurados como recebimento automático, os mesmos receberão a data de pagamento <span>${this.moment(this.form.date_release).format('DD/MM/YY')}</span>. Deseja continuar?</div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
         }).then(res => {
          if (res.isConfirmed) {
            this.accountReceive()
          }
        })
      },
      accountReceive() {
        const isLoading = this.$loading.show();
  
        if (!this.selectedInstallments.length) return;
  
        const data = this.selectedInstallments.map(installment => ({
          id: installment.id,
          payment_date: this.form.date_release,
          status: 'paid_out',
        }))
  
        this.api.updateFinancialInstallments(data)
          .then(() => {
            this.clearForm();
            this.$toast.success('Conta(s) recebida(s) com sucesso!');
            this.clearReceivedBills();
            this.getBillsToReceive();
            this.$bvModal.hide('selected-bills-to-receive-modal');
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => isLoading.hide());
      },
  
      clearForm() {
        this.form = {
          id: null,
          user: null,
          date_release: new Date(),
        }
        this.getUser()
        this.validated = false;
      },
  
      isValidState(model) {
        return this.errors[model] !== true;
      },
  
      closeModal() {
        this.clearForm();
        this.$bvModal.hide('selected-bills-to-receive-modal');
        this.$emit('onHide');
      },
  
      removeBill(id) {
        this.selectedInstallments.splice(id, 1);
        this.getFinalAmount();
      },
  
      clearReceivedBills() {
        this.selectedInstallments.forEach(element => {
          this.selectedInstallments.splice(element.id, 1)
        });
      },
  
      getFinalAmount() {
        this.total = this.selectedInstallments.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
      }
    },
  }
  </script>
  <style lang="scss">
  #selected-bills-to-receive-modal {
    .modal-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      /* max-width: 750px; */
  
      .modal-body {
        padding: 0 !important;
  
        .modal-header {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px !important;
  
          .modal-title {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: var(--type-active);
          }
  
          .icon {
            width: 24px;
            height: 24px;
            fill: #7F91D2;
          }
        }
  
        .body-content {
          margin: 24px;
  
          .md-button {
            text-align: right;
          }
  
          .td-header {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            color: var(--type-active);
            border: none;
          }
  
          .td-body {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: var(--type-active);;
            border-top: 1px dashed #C6CEEB;
  
            .change-type {
              display: flex;
              padding: 2px 8px;
              border-radius: 30px;
              color: white;
              font-size: 12px;
              justify-content: center;
              background-color: var(--blue-300);
            }
  
            .options {
              cursor: pointer;
              width: 24px;
              height: 28px;
              position: relative;
              display: inline-block;
  
              .more-icon {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }
  
              .menu {
                width: 141px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                padding: 0;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                display: none;
  
                .mtb-12 {
                  margin: 12px 0;
                }
  
                .btn {
                  font-weight: 600;
                  width: 100%;
  
                  &.remove {
                    color: var(--states-error);
                  }
  
                  &.btn-link {
                    text-align: left;
                    padding: 12px 18px !important;
                  }
                }
              }
  
              &:hover {
                .more-icon {
                  transform: rotate(90deg);
                }
  
                .menu {
                  opacity: 1;
                  display: flex;
                  z-index: 10;
                }
              }
            }
          }
  
          .category-text {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: var(--neutral-600);
          }
  
          .final-amount-text {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: var(--neutral-600);
          }
  
          .table {
            margin: 0;
            padding: 0;
          }
  
          .hr {
            hr {
              padding: 0 !important;
              margin: 0 0 24px !important;
            }
          }
        }
      }
    }
  }
  
  #modal-titles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--dark-blue);
  }
  
  #bill-menu-actions {
    text-align: right;
  
  }
  
  #total-text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }
  
  #total-amount {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--neutral-600);
  }
  </style>
  